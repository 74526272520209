import React from "react"
import { Box } from "@sqymagma/elements"
import FaqTitle from "../components/faq/title"
import FaqItem from "../components/faq/item"
import FaqCBody from "../components/faq/contentBody"
import styled from "styled-components"

const Ul = styled.ul`
  padding: 1rem;
`

const Ol = styled.ol`
  padding: 1rem;
`

const Li = styled.li`
  list-style: square;
  padding-left: 1rem;
`
const Lo = styled.li`
  padding-left: 1rem;
  list-style: decimal;
`
const Lr = styled.li`
  padding-left: 1rem;
  list-style: lower-roman;
`

const FaqContent = () => {
  return (
    <Box pb="spc8">
      <FaqTitle>1. Cambio de Uso</FaqTitle>
      <FaqItem
        title="1.1. ¿Cómo hacer un cambio de uso de local a vivienda en el término municipal de
Madrid?"
        tagidfaqquestion="TM65-FAQ-MóduloCambioUso-1.1"
      >
        <FaqCBody>
          El <b>cambio de uso de local a vivienda</b> requiere de una serie de trámites legales
          ajustados tanto a legislación municipal, catastral, así como requerimientos de las
          compañías distribuidoras de luz y agua. Básicamente, para habilitar un local como
          vivienda, se deben seguir una serie de pasos:
          <Ol>
            <Lo>
              Búsqueda de un local comercial apto técnica y jurídicamente hablando para obtener el
              cambio de uso a vivienda (participación de arquitectos expertos en la materia, así
              como de abogados).
            </Lo>
            <Lo>
              Estudio exhaustivo de la viabilidad del <b>cambio de uso</b> y la rentabilidad
              económica que puede ofrecer la propiedad.
            </Lo>
            <Lo>
              Presentación de un proyecto técnico firmado por un técnico competente y visado por el
              cOlegio oficial de arquitectos que cumpla los aspectos de accesibilidad y condiciones
              de habitabilidad regidos en el Código Técnico de Edificación.
            </Lo>
            <Lo>
              Aprobación del órgano competente del Ayuntamiento de Madrid para realizar las obras de
              cambio de uso a vivienda y/o segregación del local hasta la obtención de la Licencia
              de Primera Ocupación (L.P.O.)
            </Lo>
            <Lo>
              Otorgamiento ante notario público de la correspondiente escritura de cambio de uso y/o
              segregación del local a vivienda/s y su inscripción en el Registro de la Propiedad.
            </Lo>
          </Ol>
          <p>
            Living Alternatives quiere hacer las cosas “customer-friendly”: busca simplificar la
            gestión de la inversión para que nuestros clientes sólo tengan que preocuparse del
            rendimiento económico de las propiedades resultantes.
          </p>
          <p>
            Por ello, ofrecemos un servicio ágil e integral, que nos permite reducir tiempos, costes
            y trámites. Nuestro equipo te explicará paso a paso{" "}
            <b>cómo hacer un cambio de uso de local a vivienda,</b> haciendo de este un proceso
            fácil y exitoso.
          </p>
        </FaqCBody>
      </FaqItem>
      <FaqItem
        title="1.2. ¿Cuánto cuesta transformar un local a vivienda?"
        tagidfaqquestion="TM66-FAQ-MóduloCambioUso-1.2"
      >
        <FaqCBody>
          <b>El presupuesto del cambio de uso de local a vivienda</b> está en función de algunos
          parámetros determinantes:
          <Ol>
            <Lr>Precio de adquisición del local comercial </Lr>
            <Lr>Costes de reforma del mismo</Lr>
            <Lr>
              Tributos, tasas del Ayuntamiento, costes notariales, de registro y fees de gestión.
            </Lr>
          </Ol>
          <p>
            En Living Alternatives sólo debe preocuparse de hacer la inversión. Nuestro equipo de
            profesionales se encarga de obtener una <b>estimación personalizada</b> para cada uno de
            los clientes, optimizando al máximo su inversión.
          </p>
        </FaqCBody>
      </FaqItem>
      <FaqItem
        title="1.3. ¿Cuánto tipo se tarda en todo el proceso de cambio de uso?"
        tagidfaqquestion="TM67-FAQ-MóduloCambioUso-1.3"
      >
        <FaqCBody>
          El tiempo varía fundamentalmente en función del tiempo que el Ayuntamiento de Madrid tarde
          en conceder la correspondiente Licencia de Primera Ocupación. De forma aproximada el
          proceso completo, desde la adquisición del local, es de unos 10 meses.
        </FaqCBody>
      </FaqItem>
      <FaqTitle>2. Invertir en Locales</FaqTitle>
      <FaqItem
        title="2.1. ¿Cómo invertir en locales comerciales?"
        tagidfaqquestion="TM68-FAQ-MóduloInvertirLocales-2.1"
      >
        <FaqCBody>
          <p>
            Living Alternatives encuentra una oportunidad única de mercado: el{" "}
            <b>cambio de uso del local comercial a vivienda.</b>
          </p>

          <p>
            Con una inversión diversificada y un escenario económico complicado en España, nos
            encontramos con buenas <b>oportunidades de compra de locales comerciales.</b> En estos
            últimos años se ha producido un periodo de escasa oferta de viviendas de calidad en un
            entorno de alta demanda. La idea de Living Alternatives: adquirir locales comerciales
            para transformarlos posteriormente en viviendas reformadas y decoradas que generan{" "}
            <b>altos porcentajes de rentabilidad.</b>
          </p>

          <p>
            Tras la experiencia conseguida en anteriores cambios de uso a vivienda, Living
            Alternatives le ofrece la posibilidad de aprovechar una de las fórmulas con mayor
            rentabilidad en el mercado actual.
          </p>

          <p>
            Si tiene alguna duda sobre <b>cómo invertir en locales comerciales,</b> Living
            Alternatives le ofrecerá todo tipo de respuestas, no dude en contactar con nosotros.
          </p>
        </FaqCBody>
      </FaqItem>
      <FaqItem
        title="2.2. ¿Es rentable convertir un local en vivienda?"
        tagidfaqquestion="TM69-FAQ-MóduloInvertirLocales-2.2"
      >
        <FaqCBody>
          <p>
            <b>Invertir en locales comerciales para el cambio de uso</b> se ha convertido en una de
            las mejores opciones de inversión inmobiliaria en el mercado. Además, ciudades como
            Madrid continúan siendo la mejor decisión de inversión. Si todavía no conoce todos sus
            beneficios, aquí encontrará las razones por las que esta práctica se ha convertido en la
            mejor decisión:
          </p>
          <Ul>
            <Li>
              Los locales comerciales generan rentabilidades atractivas, superior al de las
              viviendas y otro tipo de activos inmobiliarios.
            </Li>
            <Li>
              Este tipo de inmuebles generan, una vez transformados en viviendas, una alta
              rentabilidad bien a través de su alquiler bien a través de la vena del mismo.{" "}
            </Li>
            <Li>
              Un local comercial susceptible de ser transformado su a vivienda se convierte en un
              activo inmobiliario que permite reducir riesgos. La buena gestión de los mismos
              mediante contratos de alquiler y/o venta genera una alta rentabilidad de la inversión.
            </Li>
          </Ul>
          <p>
            <b>
              Convertir un local en vivienda se alza como una de las fórmulas más rentables de
              inversión en España.
            </b>
          </p>
          <p>
            Living Alternatives utiliza un innovador modelo de negocio que aprovecha la{" "}
            <b>rentabilidad del sector inmobiliario</b> y otorga a sus clientes proyectos de alto
            rendimiento y gran atractivo.
          </p>
        </FaqCBody>
      </FaqItem>
      <FaqTitle>3. Requisitos Legales</FaqTitle>
      <FaqItem
        title="3.1. ¿Cuáles son los requisitos para convertir un local comercial en vivienda en el municipio de Madrid?"
        tagidfaqquestion="TM70-FAQ-ModuloRequisitosLegales-3.1"
      >
        <FaqCBody>
          <p>
            De forma resumida, los{" "}
            <b>requisitos técnicos para convertir un local comercial en vivienda</b> son:
          </p>
          <Ul>
            <Li>
              Tamaño mínimo de 25 metros cuadrados útiles (requisitos de superficie de{" "}
              <b>una vivienda mínima</b>)
            </Li>
            <Li>No encontrase por debajo del nivel de la acera</Li>
            <Li> La fachada de la vivienda debe ocupar una longitud superior a 3 metros </Li>
            <Li> Una altura mínima del suelo al techo </Li>
            <Li>
              La futura vivienda debe contar con una superficie mínima de huecos destinados a la
              iluminación y ventilación de la vivienda
            </Li>
            <Li>
              La vivienda debe cumplir con las condiciones de accesibilidad y seguridad que en
              materia de prevención de incendios.
            </Li>
          </Ul>
        </FaqCBody>
      </FaqItem>
      <FaqItem
        title="3.2. ¿Cuál es la normativa aplicable para realiza el cambio de uso de local a vivienda?"
        tagidfaqquestion="TM71-FAQ-ModuloRequisitosLegales-3.2"
      >
        <FaqCBody>
          <p>
            Un proyecto de <b>cambio de uso de local a vivienda</b> es un proceso integral que debe,
            en todas sus fases, tramitarse bajo la normativa aplicable en cada momento: Plan General
            de Ordenación Municipal de Madrid, el Código Técnico de la Edificación, Normas de
            Habitabilidad y criterios urbanísticos emanados de los órganos competentes del
            Ayuntamiento de Madrid.{" "}
          </p>
          <p>
            En dicha normativa se recogen los requisitos legales a tener en cuenta durante{" "}
            <b>el cambio de uso de local a vivienda.</b>
          </p>
        </FaqCBody>
      </FaqItem>
      <FaqItem
        title="3.3. ¿Cuál es la superficie mínima de vivienda en Madrid?"
        tagidfaqquestion="TM72-FAQ-ModuloRequisitosLegales-3.3"
      >
        <FaqCBody>
          <p>
            Según el Plan General de Ordenación Urbana de Madrid,
            <b> la superficie mínima de vivienda en Madrid</b> debe ser superior a 25 metros
            cuadrados útiles, sin incluir terrazas, balcones, balconadas, miradores, tendederos ni
            espacios con altura libre de piso inferior a 2,20 metros.
          </p>
        </FaqCBody>
      </FaqItem>
      <FaqItem
        title="3.4. ¿Cómo registrar una vivienda en el Registro de la Propiedad?"
        tagidfaqquestion="TM73-FAQ-ModuloRequisitosLegales-3.4"
      >
        <FaqCBody>
          <p>
            Para <b>registrar una vivienda en el Registro de la Propiedad,</b> se requiere:
          </p>
          <Ul>
            <Li>
              En primer lugar, en el momento de la adquisición del local comercial se requiere la
              inscripción del mismo a favor del nuevo propietario. Para ello se requiere de: la
              copia autorizada de la escritura pública de compraventa otorgada ante notario; el el
              pago del Impuesto de Transmisiones Patrimoniales y Actos Jurídicos Documentados (en su
              modalidad de Transmisión Patrimonial Onerosa) al tipo impositivo en vigor del 6%, y la
              justificación de la notificación al Ayuntamiento de la compraventa a efectos de que el
              vendedor del local proceda al pago del IIVTNU (conocida como plusvalía municipal).{" "}
            </Li>
            <Li>
              Una vez se obtiene la Licencia de Primera Ocupación de la vivienda y para su
              inscripción ante el Registro de la Propiedad se deberá acudir de nuevo al notario y
              realizar el pago de ciertos tributos (al tipo impositivo actual del 0,75%)
            </Li>
          </Ul>
        </FaqCBody>
      </FaqItem>
    </Box>
  )
}

export default FaqContent

import React from "react"
import { Box, Text } from "@sqymagma/elements"

const FaqTitle = ({ children }) => (
  <Box pt="128px" pb="64px">
    <Text textStyle="h3" weight="500">
      {children}
    </Text>
  </Box>
)

export default FaqTitle

import React from "react"
import { Box, Text } from "@sqymagma/elements"

const cBody = ({ children }) => (
  <Box>
    <Text textStyle="P">{children}</Text>
  </Box>
)

export default cBody

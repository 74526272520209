import React from "react"

import Layout from "../modules/layout"
import InnerHero from "../modules/m14_InnerHero"
import LeaveData from "../modules/m21B_leaveDataContact"
import FaqContent from "../modules/m31FaqContent"
import BlockInner from "../components/blockinner"
import m21 from "../data/m21B"

const FaqPage = () => {
  return (
    <Layout
      title="Cómo hacer un cambio de uso | Living Alternatives-FAQ"
      desc="Si tienes dudas sobre cómo segregar un local en viviendas, nuestro equipo te da todas las respuestas: invertir en locales, cambios de uso, requisitos legales…"
      keywords="como hacer un cambio de uso de local a vivienda,
      como transformar un local comercial en viviendas,
      cuanto cuesta pasar un local a vivienda,
      segregación de una vivienda,
      como invertir en locales comerciales,
      es rentable convertir un local en vivienda,
      requisitos para convertir local comercial en vivienda,
      certificado estructural de vivienda,
      certificado de idoneidad vivienda,
      normativa cambio de uso de local a vivienda,
      superficie minima vivienda madrid,
      inspección técnica de edificios,
      inspección técnica de edificios precio,
      como registrar una vivienda en el registro de la propiedad,
      cuanto cuesta inscribir una vivienda en el registro,
      registro entidades urbanísticas colaboradoras,
      informe evaluación de los edificios ,
      iee madrid,
      precios iee madrid,
      que es el certificado de eficiencia energética,
      como hacer un certificado de eficiencia energética,
      como se registra el certificado de eficiencia energética"
      url="preguntas-frecuentes-como-hacer-un-cambio-de-uso"
    >
      <InnerHero
        bc="Preguntas frecuentes"
        title="Preguntas frecuentes"
        url="preguntas-frecuentes-como-hacer-un-cambio-de-uso"
        tagidbotshare="ooo"
      />
      <BlockInner>
        <FaqContent />
      </BlockInner>

      <LeaveData data={m21} tagidbotcontact="TM74-FAQ-MóduloExito-BotonCasoExito" />
    </Layout>
  )
}

export default FaqPage

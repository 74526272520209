import React, { useState } from "react"
import { Flex, Text, Box } from "@sqymagma/elements"
import styled from "styled-components"
import { IcnSystem } from "../IconSystem/IcnSystem"

const T = styled(Text)`
  list-style-type: square;
  padding-top: ${p => (p.show ? "16px" : "0px")};
  opacity: ${p => (p.show ? "1" : "0")};
  height: ${p => (p.show ? "auto" : "0px")};
  transition: 500ms;
`
const Card = styled(Flex)`
  &:hover {
    cursor: pointer;
  }
`

const ItemBox = styled(Flex)`
  border-bottom: ${p => (p.show ? "none" : "1px solid " + p.theme.color.brand04)};
  background-color: ${p => (p.show ? p.theme.color.brand04 : "white")};
  transition: 500ms;
  padding-bottom: ${p => (p.show ? "48px" : "0px")};
`
const Content = styled(Flex)`
  pointer-events: none;
`
const ItemTitle = styled(Text)`
  list-style-type: square;
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const Icn = styled(IcnSystem)`
  height: 48px;
  ${p => p.theme.mq.s} {
    height: 64px;
  }
  ${p => p.theme.mq.xl} {
    height: 96px;
  }
`

const FaqItem = ({ children, tagidfaqquestion, title, ...props }) => {
  const [show, setShow] = useState(false)

  return (
    <ItemBox flexDirection="column" show={show} {...props}>
      <Card
        flexDirection="row"
        justifyContent="space-between"
        onClick={() => setShow(!show)}
        py={["spc", "20px"]}
        pl={["spc", "80px"]}
        pr={[0, "spc2"]}
        id={tagidfaqquestion}
      >
        <Flex py={["8px", "24px"]}>
          <ItemTitle as="li" textStyle="p_t" title={title}>
            {title}
          </ItemTitle>
        </Flex>
        <Box>
          {show ? <Icn name="icnless" fill="brand01" /> : <Icn name="icnplus" fill="brand01" />}
        </Box>
      </Card>
      <Content flexDirection="column" px={["spc", "spc6"]}>
        <T textStyle="p" className={show ? "show" : "hide"} show={show}>
          {children}
        </T>
      </Content>
    </ItemBox>
  )
}

export default FaqItem
